.material-tooltip {
    padding: 10px 8px;
    font-size: 1rem;
    z-index: 2000;
    background-color: transparent;
    border-radius: 2px;
    color: #fff;
    min-height: 36px;
    line-height: 120%;
    opacity: 0;
    display: none;
    position: absolute;
    text-align: center;
    max-width: calc(100% - 4px);
    overflow: hidden;
    left:0;
    top:0;
    pointer-events: none;
    will-change: top, left;
}

.backdrop {
  position: absolute;
  opacity: 0;
  display: none;
  height: 7px;
  width: 14px;
  border-radius: 0 0 14px 14px;
  background-color: #323232;
  z-index: -1;
  transform-origin: 50% 10%;

  will-change: transform, opacity;
}
