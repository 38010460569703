.page-product {
    .content-container {
        padding-top: 24px;
        padding-bottom: 32px;
        @include min-md {
            padding: 80px 0 40px;
        }
    }
    .product {
        &-gallery {
            position: relative;
            max-width: 685px;
            margin: 0 auto;
            padding-top: 24px;
            padding-bottom: 40px;
            &__flag--new {
                position: absolute;
                z-index: 9;
                top: 115px;
                left: 15px;
            }
        }
        &-label {
            position: absolute;
            z-index: 9;
            top: 115px;
            right: 15px;
            margin: 0;
            padding: 0;
            li {
                margin: 0 0 15px 0;
                margin-bottom: 20px;
                padding: 0;
                list-style: none;
            }
            img {
                width: 50px;
                max-width: 100%;
                @include min-md {
                    width: inherit;
                }
            }
        }
        &-info {
            margin: 0 0 20px;
            padding: 20px 20px 30px;
            border-radius: 15px 15px 15px 15px;
            background: $color-8;
            @include min-md {
                margin-top: 0;
                border-radius: 15px 0 0 15px;
            }
            &__separator {
                height: 1px;
                margin-top: 15px;
                margin-bottom: 15px;
                border: 0;
                background: #ccc;
            }
            &__title {
                font-size: 18px;
                font-weight: 800;
                margin: 0 0 5px;
                text-transform: uppercase;
                span {
                    font-weight: 600;
                    margin-left: 3px;
                }
            }
            &__desc {
                font-size: 13px;
                display: block;
                clear: both;
                text-transform: uppercase;
            }
            .product-attributes {
                margin: 0;
                padding-right: 4px;
                padding-left: 4px; // h2,
                &__title {
                    font-size: 13px;
                    font-weight: 700;
                    margin-bottom: .5em;
                    text-transform: uppercase;
                }
                &__desc {
                    font-size: 12px;
                    margin: 0 0 5px;
                    padding: 0;
                    color: #666;
                }
                &__line {
                    font-size: 14px;
                    font-weight: 500;
                    display: inline-block;
                    margin: 0 -7px 10px;
                    padding: 8px 15px;
                    color: #fff;
                    border-radius: 20px;
                    background-color: #999;
                }
                &__subline {
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0 0 5px;
                }
                .icon-download {
                    display: inline-block;
                    margin-right: 5px;
                    text-align: center;
                    color: #000;
                    &:after {
                        display: block;
                        width: 18px;
                        height: 5px;
                        margin-top: -3px;
                        content: '';
                        border: 2px solid #000;
                        border-top: none;
                    }
                }
            }
        }
        &-share {
            padding-top: 15px;
            border-top: 1px solid #cdcdcd;
            .twitter-share-button,
            .fb-share-button {
                display: inline-block;
                margin-right: 5px;
            }
            .fb-share-button {
                vertical-align: 3px;
            }
        }
    }
    .widget-contact {
        font-size: 12px;
        max-width: 270px;
        margin: 0 auto;
        padding: 20px;
        color: #222;
        border-radius: 10px;
        background-image: url('../../images/contato-atendente.jpg');
        background-repeat: no-repeat;
        background-color: $color-8;
        background-position: 100px bottom;
        background-blend-mode: multiply;
        .widget-contact__title {
            font-size: 14px;
            font-weight: 700;
            margin: 0 0 2px;
            text-transform: uppercase;
            color: $color-1;
        }
        .address-list {
            margin: 0;
        }
        li {
            margin-bottom: 5px;
            padding-left: 25px;
            p {
                margin-bottom: 5px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            a {
                font-size: 13px;
                color: #222;
                &:hover {
                    color: red;
                }
            }
            &.address-list__tel {
                font-size: 16px;
                font-weight: 700;
                position: relative;
                a {
                    font-size: 16px;
                }
                i {
                    &:before {
                        margin-left: 0;
                    }
                }
            }
            .icon-mail-alt {
                font-size: 16px;
            }
        }
    }
    .button-edit-product {
        line-height: 30px;
        display: inline-block;
        float: right;
        margin: -50px 18px 20px 0;
        padding: 0 15px;
        border-radius: 4px;
        background: #ffe894;
        color: #9d7b00;
        &:hover {
            opacity: 0.7;
        }
    
        @media (max-width: 1000px) {
            display: none;
        }
    }

    .budget-button {
        width: 100%;
        margin-bottom: 16px;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 17px;
    }
}
