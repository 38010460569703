//Efeito de Hover nos links de categorias
.effect-bg-sature {
    @extend %transition;
    &:hover {
        @include filter(saturate(170%));
    }
}

//Banner Responsivo
.responsive-banner {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    a {
        display: block;
    }
    img {
        position: relative;
        left: 50%;
        display: block;
        max-width: inherit;
        @include transform(translate(-50%, 0));
        .no-csstransforms & {
            left: auto;
        }
        @media (max-width: 992px) {
            width: 155%;
            max-width: none!important;
        }
    }
}

//Lista de endereço e telefone
.address-list {
    li {
        position: relative;
        margin-bottom: 10px;
        padding-left: 35px;
        i {
            font-size: 20px;
            position: absolute;
            top: -3px;
            left: 0;
            color: $color-1;
        }
        &.address-list__tel {
            font-size: 16px;
            font-weight: 600;
        }       
    }
}

//Título personalizado
.page__title-box {
    position: relative;
    margin-bottom: 30px;
    border-bottom: 1px solid $color-1;
    display: flex;
    margin-top: 16px;
    @include min-md {
        margin-top: inherit;
    }
    .page__title {
        font-size: 22px;
        font-weight: 600;
        position: relative;
        bottom: -9px;
        display: inline-block;
        margin: 0;
        padding: 0 10px 0 0;
        color: $color-1;
        background: #fff;
    }
}

.page__desc {
    margin-bottom: 40px;
}

//Redes Sociais
.social {
    margin: 10px 0 0;
    @include min-md {
        float: right;
    }
    &-heading {
        font-weight: 600;
        margin-right: 10px;
        text-transform: uppercase;
    }
    li {
        display: inline-block;
        
        a {
            &:hover {
                i {
                    color: $color-1;
                }
            }
        }
        i {
            font-size: 28px;
            transition: ease all 0.3s;
        }
    }
}

//Bloco de Atendimento
.attendance {
    margin-top: 60px;
    margin-bottom: 40px;
    .address-list {
        li {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ccc;
            
            &:first-child {
                font-size: 16px;
                font-weight: 700;
                i {
                    font-size: 20px;
                    position: absolute;
                    top: -3px;
                    left: 0;
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .attendance__title {
        font-size: 13px;
        font-weight: 400;
    }
    .address-list {
        margin: 0;
    }
    .social {
        float: none;
        margin: 0 0 40px;
        li {
            &:first-child {
                font-weight: 400;
                display: block;
                margin-bottom: 5px;
            }
            a {
                display: inline-block;
                width: 26px;
                height: 26px;
                line-height: 26px;
                color: #fff;
                background: #000;
                border-radius: 3px;
                i {
                    font-size: 18px;
                }
            }
        }
    }
}

.parsley-errors-list {
    font-size: 11px;
    margin: -5px 0 0;
    color: $color-1;
}

.form-action {
    margin-top: 20px;
}

.breadcrumbs {
    margin: 0 0 10px;
    background: #eee;
    padding: 8px 14px;
    display: block;
    border-radius: 5px;
    > li {
        font-size: 13px;
        display: inline-block;
        &:last-child {
            font-weight: 600;
            &:after {
                content: '';
            }
        }
        &:after {
            padding: 0 2px 0 7px;
            content: '/';
        }
        a {
            &:hover {
                color: $color-1;
            }
        }
    }
}

.pagination--top {
    padding-top: 0;
    margin-bottom: 0!important;
}

.pagination {
    float: right;
    margin: 0 0 10px;
    .page {
        width: 22px;
        height: 22px;
        overflow: hidden;
        font-size: 13px;
        line-height: 20px;
        padding: 0;
        border-radius: 1em;
    }
    //Controles
    .control {
        width: 22px;
        height: 22px;
        margin: 0;
        overflow: hidden;
        text-indent: -200px;
        &:before {
            display: block;
            display: block;
            width: 22px;
            height: 22px;
            font-family: "fontello";
            content: '\e806';
            text-indent: 0;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: $primary-color;
        }
        &.next:before {
            content: '\e803';
        }
        &.inactive {
            opacity: 1;
            &:before {
                color: #000;
            }
        }
    }
}

//Menu sanfona em produtos
.collapsible-custom {
    padding: 0;
    color: #fff;
    li {
        margin-bottom: 5px;
    }
    &.collapsible {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .collapsible-header {
        min-height: 20px;
        padding: 0;
        border-radius: 20px;
        .icon {
            margin-left: -8px;
        }
        &:hover {
            @include filter(saturate(150%));
        }
        &>span, &>a {
            display: block;
            line-height: 1.3;
            min-height: 20px;
            padding: 6px 15px;
            border-radius: 20px;
        }
    }
    .collapsible-body {
        margin: 0;
        padding: 10px 15px;
        border-bottom: none;
        li {
            display: block;
            margin-bottom: 10px;
            &.active {
                font-weight: bolder;
            }
            a {
                display: block;
                @extend %transition;
                &:hover {
                    text-indent: 2px;
                }
            }
            .icon {
                margin-left: -8px;
            }
        }
    }
}

//Matéria Prima
.filter-feedstock {
    li {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            &:hover {
                color: $color-1;
            }
        }
    }
    .feedstock {
        font-size: 12px;
        margin-right: 3px;
        font-weight: 600;
        line-height: 1.8;
        display: inline-block;
        width: 24px;
        height: 24px;
        padding: 2px;
        text-indent: 3px;
        color: #fff;
        border-radius: 50%;
        background: #666;
        &:hover {
            opacity: 0.8;
            transition: ease all 0.3s;
        }
        &--capsule {
            width: inherit;
            padding: 2px 20px;
            border-radius: 20px;
            font-size: 14px;
            height: inherit;
        }
    }
}

//Cores disponíveis
.product-available-colors {
    width: 100%;
    text-align: center;
    overflow: hidden;
    &__title {
        margin: 0 0 5px;
    }
    ul {
        width: 90%;
        margin: 0 auto;
        li {
            display: inline-block;
            width: 26px;
            height: 30px;
            list-style: none;
            &[data-medium-image] {
                cursor: pointer;
                &:hover {
                    @include filter(saturate(170%));
                }
                &.active {
                    span {
                        border-color: #fff;
                        &.white, &.translucent {
                            border-color: #ccc;
                        }
                        box-shadow: 0 0 7px 1px rgba(#EC1B23, 0.7);
                    }
                }
            }
            span {
                display: block;
                width: 22px;
                height: 22px;
                margin-left: auto;
                margin-right: auto;
                border: 2px solid #D7D7D7;
                line-height: 50px;
                overflow: hidden;
                margin-top: 5px;
                -webkit-border-radius: 100%;
                border-radius: 100%;
                &.white, &.assorted, &.translucent, &.cristal, &.transparent {
                    background: #fff !important;
                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        text-align: center;
                        line-height: 17px;
                    }
                }
                &.translucent {
                    background: #fff !important;
                    &:before {
                        content: 'T';
                    }
                }
                &.cristal {
                    background: #f0f0f0 !important;
                    &:before {
                        content: 'C';
                    }
                }
                &.transparent {
                    background: #f0f0f0 !important;
                    &:before {
                        content: 'T';
                    }
                }
                &.assorted {
                    background: url('../../images/assorted-bg.png') center center no-repeat !important;
                    &:before {
                        content: '';
                    }
                }
            }
        }
    }
}

 .c-select {
    border: solid 1px #9e9e9e;
    font-size: 1rem;
    padding: 8px;
    width: 100%;
    margin-bottom: 10px;
}