/* LIGHTBOX */
.lightbox-custom {
    @media (max-width: 700px) {
        margin-left: -20px;
        margin-top: -20px;
    }

    .fancybox-skin {
        border-radius: 14px;
        box-shadow: none;

        @media (max-width: 700px) {
            border-radius: 0;
        }

        padding: 5px !important;
    }

    .fancybox-title {
        padding: 10px 10px 5px 10px;
    }

    .fancybox-close {
        height: 45px;
        width: 45px;
        right: 0;
        top: 0;

        background: none;

        &:before {
            content: '\e804';
            font-family: "fontello";
            display: block;
            font-size: 27px;
            text-align: center;
            line-height: 45px;
            color: $color-1;

            -moz-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    @media (max-width: 700px) {
        .fancybox-close {
            position: fixed;
            margin: 0;
            right: 0;
            top: 0;

            background: #f1f1f1;

            border-radius: 0 0 0 10px;
        }
    }

    .fancybox-prev, 
    .fancybox-next {
        span {
            display: none;
        }

        &:before {
            content: '\e803';
            font-family: "fontello";
            font-size: 30px;
            display: block;
            position: absolute;
            top: 50%;
            width: 26px;
            height: 75px;
            margin-top: -37px !important;

            line-height: 75px;
            text-align: center;
            color: $color-1;

            background: rgba(#fff, 0.4);
        }
    }
    .fancybox-prev {
        &:before {
            left: 4%;
            content: '\e806';
        }
    }
    .fancybox-next {
        &:before {
            right: 4%;
        }
    }
}
.fancybox-overlay {
    background: rgba(#333, 0.9);
}