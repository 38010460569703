//Plugin bckGallery
$zoomWidth: 685px;
$zoomHeight: 520px;
#imageGallery {
    position: relative;
    width: 100%;
    max-width: $zoomWidth;
    margin: 15px auto 30px;
    &:empty {
        height: 390px;
        background: url('../../images/no-image.png') #F8F8F8 no-repeat center center;
        @media (max-width: 991px) {
            height: 1px;
            opacity: 0;
        }
    }
  
    .mask {
        position: relative;
        width: 100%;
        max-width: $zoomWidth;
        margin: 0 auto;
    }
    .bckGallery_zoom {
        width: 100%;
        max-width: $zoomWidth;
        margin: 0 0 30px 0;
        min-height: $zoomHeight;
        @media (max-width: 780px) {
            min-height: 300px;
        }
        @media (max-width: 450px) {
            min-height: 200px;
        }
        .imagem {
            position: absolute;
            z-index: 7;
            width: 100%;
            max-width: $zoomWidth;
            text-align: center;
            span {
                //Icone Zoom
                display: none;
            }
            a {
                display: block;
                width: 100%;
                background: #f00;
            }
            img {
                width: 100%;
                max-width: 100%;
            }
        }
        .engodo {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 0;
            left: 0;
            overflow: hidden;
            text-indent: -10000px;
            img {
                display: block;
                width: 100%;
                margin: 0 auto;
                opacity: 0.99;
            }
        }
        .fundoTransicao {
            position: absolute;
            z-index: 5;
            width: 100%;
            max-width: $zoomWidth;
            div {
                margin: 0 auto;
                height: 100%;
                overflow: hidden;
                max-width: 100%;
                opacity: 0.6;
                background-size: 100% auto !important;
            }
        }
        .marcador {
            width: 100%;
            max-width: $zoomWidth;
            text-indent: -10000px;
            overflow: hidden;
        }
        .loader {
            display: none;
            position: absolute;
            z-index: 9;
            width: 50px;
            height: 50px;
            margin-top: -25px !important;
            margin-left: -25px !important;
            top: 50%;
            left: 50%;
            overflow: hidden;
            background: url('../../images/ajax-loader.png') no-repeat center center;
            border-radius: 6px;
        }
        .legenda {
            position: absolute;
            z-index: 5;
            width: 100% !important;
            right: 0;
            bottom: -25px;
            text-align: center;
            span {
                display: inline-block;
                color: #000;
                letter-spacing: 1px;
                font-size: 13px;
                font-weight: 400;
            }
        }
    }
    // Botões de Controle do Zoom
    .bckGallery_ctrl_visualizacao_anterior,
    .bckGallery_ctrl_visualizacao_proximo {
        width: 32px;
        height: 80px;
        position: absolute;
        z-index: 9;
        background: url('../../images/icons/icon-arrow.png') center center transparent no-repeat;
        text-indent: -9999px;
        &:hover {
            opacity: 0.8;
        }
    }

    .bckGallery_ctrl_visualizacao {
        &_anterior {
            left: 16px;
            transform: rotate(180deg);
        }

        &_proximo {
            right: 16px;
        }
    }
    /* Thumbs */
    .thumbs-mask {
        width: 600px;
        margin: 20px auto 10px;
    }
    .bckGallery_thumbs {
        position: relative;
        z-index: 7;
        width: 600px;
        overflow: hidden;
        ul {
            float: left;
            width: 9999px;
            margin: 0;
            li {
                float: left;
                list-style: none;
                margin: 0 15px 0 0;
                a {
                    position: relative;
                    width: 108px;
                    overflow: hidden;
                    text-align: center;
                    display: flex;
                    align-items: center;
                     -webkit-transition: ease all 0.3s;
                           -moz-transition: ease all 0.3s;
                            -ms-transition: ease all 0.3s;
                             -o-transition: ease all 0.3s;
                                transition: ease all 0.3s;
                    &:hover {
                        opacity: 0.8;
                        -webkit-transition: ease all 0.3s;
                           -moz-transition: ease all 0.3s;
                            -ms-transition: ease all 0.3s;
                             -o-transition: ease all 0.3s;
                                transition: ease all 0.3s;
                    }
                    img {
                        display: block;
                        width: 100%;
                    }
                    &:hover {
                        border-color: #333;
                    }
                    &.fixo {
                        border-color: #EA1B25;
                    }
                    &.hidden {
                        display: none;
                    }
                }
            }
        }
    }
    // Setas de Controle de páginas de thumbs
    .bckGallery_pag_anterior,
    .bckGallery_pag_proxima {
        position: absolute;
        z-index: 9;
        display: block;
        width: 40px;
        height: 108px;
        left: 0;
        bottom: 0;
        overflow: hidden;
        line-height: 500px;
        &:before {
            content: '\e806';
            font-family: "fontello";
            font-size: 26px;
            line-height: 1em;
            color: #1E1A1B;
            display: block;
            position: relative;
            width: 30px;
            top: 50%;
            margin-top: -0.5em;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }
    .bckGallery_pag_proxima {
        left: auto;
        right: 0;
        &:before {
            content: '\e803';
        }
    }
    @media (max-width: 815px) {
        .thumbs-mask {
            width: 600px * 0.5;
            height: 108px * 0.5;
        }
        .bckGallery_thumbs {
            -ms-transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -moz-transform: scale(0.5);
            -webkit-transform: scale(0.5);
            -o-transform: scale(0.5);
            -ms-transform: scale(0.5);
            transform: scale(0.5);
            ul li a {
                border-width: 2px;
            }
        }
        .bckGallery_pag_anterior,
        .bckGallery_pag_proxima {
            height: 108px * 0.5;
            left: 50%;
            margin-left: -200px;
        }
        .bckGallery_pag_proxima {
            left: 50%;
            margin-left: 160px;
        }
    }
    @media (max-width: 460px) {
        .thumbs-mask {
            width: 600px * 0.35;
            height: 108px * 0.35;
        }
        .bckGallery_thumbs {
            -moz-transform: scale(0.35);
            -webkit-transform: scale(0.35);
            -o-transform: scale(0.35);
            -ms-transform: scale(0.35);
            transform: scale(0.35);
            ul li a {
                border-width: 3px;
            }
        }
        .bckGallery_pag_anterior,
        .bckGallery_pag_proxima {
            width: 20px;
            height: 40px;
            margin-left: -126px;
            &:before {
                width: 20px;
                font-size: 20px;
                line-height: 1.1;
            }
        }
        .bckGallery_pag_proxima {
            margin-left: 105px;
        }
    }
}