.page-products {
    .content-container {
        @media (max-width: 800px) {
            &>.container {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        padding-top: 24px;
        padding-bottom: 32px;

        @include min-lg {
            padding: 80px 0 20px;
        }
    }
    .product-filter {
        margin: 0;
        padding: 15px;
        border-radius: 15px 0 0 15px;
        background: $color-8;
        .product-filter__head {
            display: block;
            margin: 0 0 10px;
            margin-bottom: 10px;
            padding: 0;
            padding: 0 0 10px;
            border-bottom: 1px solid #ccc;
            overflow: hidden;
        }
        .product-filter__head__title {
            margin: 0 0 5px;
            font-weight: 700;
            text-transform: uppercase;
            color: $color-1;
        }
        .product-filter__clear {
            display: inline-block;
            margin-top: 5px;
            margin-left: -4px;
            color: #666;
            font-weight: 400;

            i {
                font-size: 18px;
                vertical-align: -2px;
            }

            &:hover {
                color: $color-1;
            }
        }
        > ul {
            > li {
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid #ccc;
                .product-filter__title {
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0 0 15px;
                    text-transform: uppercase;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        .filter-feedstock {
            li.active {
                color: $primary-color;
            }
        }
        .filter-tipos {
            display: inline-block;
            
            li {
                display: inline-block;
                float: left;
                width: 50%;
                .shelf-tipos__item {
                    padding-top: 0;
                    padding-bottom: 0;
                }
                a {
                    display: inherit;
                }
                img {
                    height: auto;
                    margin: 0 auto;
                }
            }
        }
        .filter-volume {
            a {
                font-size: 14px;
                display: block;
                padding-top: 3px;
                padding-bottom: 3px;
                color: #222;
                
                &:hover {
                    color: $color-1;
                }
            }
        }

        @include max-sm {
            margin-bottom: 15px;
            max-height: 45px;
            overflow: hidden;
            border-radius: 0;
            transition: ease all 0.3s;

            .product-filter__head__title {
                &:after {
                    content: '(Clique para abrir)';
                    display: inline-block;
                    margin-left: 7px;
                    vertical-align: 1px;

                    font-size: 12px;
                    font-weight: 400;
                    color: #666 !important;
                    text-transform: none;
                }
            }

            &.active {
                max-height: 73px;
            }

            &.open {
                max-height: 2000px;

                .product-filter__head__title {
                    &:after {
                        content: '(Clique para fechar)';
                    }
                }
            }
        }
    }
    .page__title {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        margin: 5px 0;
        text-transform: uppercase;
    }
    .hr {
        width: 100%;
        height: 1px;
        margin: 10px 0;
        border-top: 1px solid $color-1;
        border-bottom: none;
    }
    .sort-order {
        display: table;
        float: right;
        label {
            display: table-cell;
            float: left;
            margin-top: 7px;
            margin-right: 10px;
            color: #333;
        }
        select {
            font-size: 13px;
            height: 30px;
            margin: 0;
            color: #333;
            &:focus {
                border: 1px solid $color-1;
            }
        }
    }
    .search-result {
        margin-bottom: 10px;
        .search-result__value {
            font-size: 14px;
            font-weight: 700;
            color: $color-1;
        }
    }
    .products-list {
        margin: 0;
        .products-list__item {
            display: inline-block;
            float: left;
            width: 50%;
            margin: 0 auto;
        }

        //Quebras de linhas
        &.products-list--3-columns .products-list__item {
            width: 33.333%;

            &.clear-3 {
                clear: both;
            }

            &.clear-2:not(.clear-3) {
                clear: none;
            }

            @include max-md {
                width: 50%;
            
                 &.clear-2 {
                    clear: both;
                }

                &.clear-3:not(.clear-2) {
                    clear: none;
                }
            }

            @include max-xs {
                width: 100%;
            }
        }
        //Larguras e quebras dos itens em listas com no máximo 4 colunas
        &.products-list--4-columns .products-list__item {
            width: 25%;

            &.clear-4 {
                clear: both;
            }

            &.clear-3:not(.clear-4), 
            &.clear-2:not(.clear-4) {
                clear: none;
            }

            @include max-md {
                width: 33.333%;
            
                &.clear-3 {
                    clear: both;
                }
                &.clear-2:not(.clear-3), 
                &.clear-4:not(.clear-3) {
                    clear: none;
                }
            }

            @include max-sm {
                width: 50%;
            
                &.clear-2 {
                    clear: both;
                }

                &.clear-3:not(.clear-2), 
                &.clear-4:not(.clear-2) {
                    clear: none;
                }
            }

            @include max-xs {
                width: 100%;
            }
        }
    }

    .products-not-found {
        margin-top: 80px;

        font-size: 1.4em;
        text-align: center;

        &__clear {
            font-size: 0.75em;

            &:before {
                content: '';
                display: block;
                height: 8px;
            }
        }
    }

    .products-search-message {
        margin-bottom: 20px;
        margin-top: 15px;

        font-size: 17px;

        strong {
            font-weight: bolder;
        }
    }

    .products__search {
        position: relative;
        margin: 22px 0 0;
        .products__search__input {
            width: 100%;
            height: 34px;
            margin: 0;
            padding-right: 68px;
            padding-left: 5px;
            @extend %transition;
            
            @include min-md {
                float: right;
                padding-right: 30px;
                opacity: .7;

                &:focus {
                    width: 100%;
                    opacity: 1;
                }
            }

            @include max-md {
                &:focus {
                    border-bottom: inherit !important;
                }
            }
        }
        .products__search__btn {
            font-weight: 600;
            position: absolute;
            top: 0;
            right: 0;
            height: 34px;
            padding: 0 10px;
            color: #fff;
            border: none;
            background: #222;
            &:hover {
                opacity: .8;
            }
            @include min-md {
                width: 34px;
                text-indent: -9999px;
                background: url('../../images/lupe.svg') no-repeat center center transparent;
                background-color: #666;
                background-size: 18px 18px;
            }
        }
    }
}
