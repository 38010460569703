.mega-menu {
    width: 830px;
    margin: 0 auto;
    
    &-container {
        position: absolute;
        z-index: 10;
        top: -600px;
        left: 0;
        width: 100%;
        min-height: 200px;
        padding: 20px 0 30px;
        opacity: 0;
        border-bottom: solid 1px #ddd;
        background: transparent;
        box-shadow: 0 0 8px #666;
        transition: opacity ease .7s,top ease 0s .7s;
         &.show {
            top: 85px !important;
            opacity: 1;
           transition: opacity ease .5s,top ease 0s;
        }
        .container {
            position: relative;
            z-index: 1;
            overflow: hidden;
        }
    }
    &-header {
        background: #999;
        margin: 0 0 20px;
    }

    &__filter-top {
        float: right;
        margin-bottom: 20px;
        .mega-menu__title {
            text-transform: uppercase;
            font-weight: 700;
            display: inline-block;
            margin: 0 10px 0 0!important;
        }
        .mega-menu__filter-top__item {
            display: inline-block;
            padding: 0;
            margin: 0 5px 0 0;
        }
    }

    &__title {
        clear: both;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $color-1;
    }
    &__hr {
        clear: both;
        height: 1px;
        margin-bottom: 20px;
        border-top: 1px solid #fff;
        border-bottom: none;
    }
    &__bg {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .85;
        background: #eaeaea;
        background: -moz-linear-gradient(top, #eaeaea 0%, #fff 100%);
        background: -webkit-linear-gradient(top, #eaeaea 0%, #fff 100%);
        background: linear-gradient(to bottom, #eaeaea 0%, #fff 100%);
        //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#eaeaea', endColorstr='#ffffff', GradientType=0);
    }

    &-overlay {
        position: absolute;
        z-index: 9;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        opacity: 0;
        background: #000;
        transition: opacity ease .4s,height ease 0s .4s;
        &.show {
            top: 30px;
            height: 100%;
            opacity: .65;
            transition: opacity ease .4s,height ease 0s;
        }
    }


    .shelf-tipos .shelf-tipos__item {
        max-width: 140px;
        &:hover {
            border: solid 1px #ddd;
            border-radius: 5px;
            background: #eee;
            box-shadow: 0 1px 3px #666 inset;
        }
    }
    .nav-linhas {
        margin: 0;
        .nav-linhas__item {
            display: inline-block;
            width: 155px;
            margin-bottom: 10px;
            padding: 0 5px;
            .nav-linhas__btn {
                display: table;
                width: 100%;
            }
            .nav-linhas__btn__link {
                font-size: 14px;
                font-weight: 600;
                line-height: 1;
                display: table-cell;
                width: 100%;
                height: 40px;
                padding: 0 5px;
                text-align: center;
                vertical-align: middle;
                text-transform: uppercase;
                color: #fff;
                -webkit-border-radius: 20px;
                border-radius: 20px;
                background-color: #666;
                @extend .effect-bg-sature;
            }
        }
    }
    .slick-arrow {
        font-size: 26px;
        line-height: 1.8;
        position: absolute;
        z-index: 9;
        top: 50px;
        width: 50px;
        height: 50px;
        cursor: pointer;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 50%;
        background: transparent;
        @extend %transition;
        &:hover {
            color: #ccc;
            background: #fff;
        }
        &.icon-arrow-left {
            left: -60px;
            letter-spacing: -5px;
        }
        &.icon-arrow-right {
            right: -60px;
            letter-spacing: -12px;
        }
    }
}