.page-quem-somos {
    .page-banner {
        position: relative;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(0,0,0,0.4);
            top: 0;
            z-index: 9;
        }
    }
    .page__pictures {
        margin-bottom: 40px;
        
        figcaption {
            padding: 10px;
            text-align: center;
            font-size: 12px;
        }
    	
        img {
            margin-top: 30px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .aboutUs__main {
        @include min-md {
            margin-top: 40px;
        }
    }
}
