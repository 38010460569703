.bold,
.u-bold {
  font-weight: 600;
}
.link {
  @extend %transition;
  &:hover {
    color: #fff;
    background: $color-1;
  }
}
.u-link {
  text-decoration: underline;
  &:hover {
    color: $color-1;
  }
}
.main {
  padding-bottom: 32px;
  @include min-lg {
    padding-bottom: 80px;
    min-height: 530px;
  }
}
.page-banner {
  background: #111;
}
.content-container {
  min-height: 250px;
  @include min-lg {
    padding: 60px 0;
  }
}
.page__pictures {
  display: table;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
}
.list,
.u-list {
  margin-bottom: 30px;
  padding-left: 20px;
  li {
    margin-bottom: 16px;
    list-style-position: outside;
    list-style-type: disc;
  }
}
.dl {
  dd {
    margin: 0;
    padding: 0;
  }
}
/* ALERTS */
.message {
  display: block;
  max-width: 600px;
  margin: 0 auto 40px;
  padding: 20px;
  border-radius: 8px;
  font-size: 14px;
  text-align: left;
  line-height: 1.1em;
  font-weight: 400;
  background: #f7f7f7;
  &.message-darken {
    background: #ccc;
    color: #000;
  }
  &.message-warning {
    background: #ffffcc;
    color: #fab429;
  }
  &.message-success {
    background: #d2ffd2;
    color: #003f00;
  }
  &.message-danger {
    background: #fde8e9;
    color: #f00;
  }
  &:empty {
    display: none;
  }
  .header {
    font-weight: 700;
    text-transform: uppercase;
  }
  ul {
    margin: 15px 0 0;
  }
}
/* QUANDO FORMULÁRIO ESTÁ SENDO ENVIADO */
form {
  &.sending {
    position: relative;
    textarea,
    input,
    .g-recaptcha {
      opacity: 0.4;
    }
    .waves-input-wrapper {
      background: #999;
      cursor: default;
      input {
        cursor: default;
      }
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      width: 40px;
      height: 40px;
      top: 50%;
      left: 50%;
      margin-left: -20px;
      margin-top: -20px;
      border-radius: 5px;
      background: url('../../images/loading.gif') center center no-repeat #f00;
      background-size: 40px auto;
    }
  }
}
.form {
  &__checkbox {
    &-container {
      margin-bottom: 30px;
    }
    display: flex;
    gap: 8px;
    margin-bottom: 5px;
  }
}
/* LOADING */
.loading-page {
  position: fixed;
  z-index: 9999;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  opacity: 0.8;
  text-indent: -9999px;
  background: url('../../images/loading.gif') no-repeat center center;
  background-color: rgba(#fff, 0.7);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 6px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px 5px rgba(0, 0, 0, 0.1);
}
//Custom Labels
//Linha Freezer & Microondas
.line-bg-7 {
  background: #7ab8e0;
  background: -moz-linear-gradient(left, #7ab8e0 0%, #fd9326 100%);
  background: -webkit-linear-gradient(left, #7ab8e0 0%, #fd9326 100%);
  background: linear-gradient(to right, #7ab8e0 0%, #fd9326 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7ab8e0', endColorstr='#fd9326', GradientType=1);
}
.h2 {
  margin-top: 32px;
  margin-bottom: 16px;
}
.u-table {
  border: solid 1px #ddd;
  thead {
    background: #ddd;
  }
  tr {
    border-bottom: 1px solid #ddd;
  }
  th,
  td {
    padding: 8px;
  }
  &--lg {
    min-width: 800px;
  }
}
