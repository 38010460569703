.footer {
    &__logo {
        max-width: 150px;
        &-container{
            background: url('../../images/logo-container.png') repeat-x transparent;
            background-size: contain;
            background-position: 0 -12px;
            height: 124px;
            position: absolute;
            left: 0;
            top: 0;
            padding-left: 24px;
            padding-right: 32px;
            padding-top: 16px;
        }
    }
    &__slogan {
        font-size: 16px;
        margin: 25px 0 0;
    }
    &-top {
        position: relative;
        height: 60px;
        &-container {
            color: #fff;
            background: #000;
            @include min-md {
                border-top: 2px solid $color-1;
            }
        }
    }
    &-container {
        padding: 10px 0;
        color: #fff;
        background: #333;
        text-shadow: 0 -1px 0 #000;
        @include min-md {
            min-height: 235px;
            padding: 50px 0 30px;
            background: url('../../images/footer-bg.png') repeat-x center 0 #333;
        }
        a {
            opacity: .8;
            @extend %transition;
            &:hover {
                opacity: 1;
            }
        }
        .address-list {
            li {
                color: #ccc;
            }
        }
    }
    &__menu {
        li {
            font-weight: 600;
            margin-bottom: 10px;
            a {
                font-weight: 300;
            }
            .footer__sub {
                li {
                    font-weight: 300;
                    margin-bottom: 5px;
                }
            }
        }
    }
    &-bottom {
        color: #999; 
        @include min-sm {
            text-align: left;
        }
        &-container {
            font-size: 13px;
            padding: 15px 0;
            background: #000;
        }
    }
}
.link-catalogo {
    float: left;
    padding: 10px;
    border-top: 1px solid transparent;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    @include min-md {
        float: right;
    }
    &__icon {
        float: left;
        margin-top: 5px;
    }
    &__text {
        float: right;
        width: 200px; 
        margin-left: 5px;
        @include min-md {
            width: 80px;
        }
    }
    &:hover {
        background: #2b2b2b;
        background: -moz-linear-gradient(top, #2b2b2b 0%, #000 100%);
        background: -webkit-linear-gradient(top, #2b2b2b 0%, #000 100%);
        background: linear-gradient(to bottom, #2b2b2b 0%, #000 100%);
    }
}
.nueva-button {
    overflow: hidden;
    opacity: 0.5;
    @extend %transition;
    @include min-sm {
        float: right;
    }
    &:hover {
        opacity: 0.7;
    }
    img {
        height: 18px;
    }
}
