.whatsapp-button {
    background: #000;
    border-radius: 8px;
    bottom: 40px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    padding: 6px 12px;
    position: fixed;
    right: 60px;
    width: 120px;
    z-index: 9;

    &:after {
        content: '';
        background-image: url('../../images/icons/icon-whatsapp.png');
        height: 72px;
        position: absolute;
        right: -36px;
        top: -12px;
        width: 72px;
    }

    // &:hover {
    // 	opacity: 0.8;
    // }
}