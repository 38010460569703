body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    min-width: 300px;
    @include min-md {
        font-size: 13px;
          line-height: 1.6;
    }
}
*, *:before, *:after, input[type='search'] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*:focus {
    outline: none;
}

img {
    display: inherit;
    max-width: 100%;
}
p {
    margin: 0 0 10px;
    @include min-md {
        line-height: 1.8;
    }
}
h2, 
h3, 
h4, 
h5 {
    margin-bottom: 2em;
    font-weight: 600;
}
