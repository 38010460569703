.home {
    .slider-home {
        position: relative;
        //overflow: hidden;
        max-height: 515px;
        margin-bottom: 0!important;
        &-container {
            width: 100%;
            margin-bottom: 20px;
            text-align: center;
            overflow: hidden;
            @include min-md {
                margin-bottom: 40px;
                //border-bottom: 2px solid $color-1;
                background: #111;
            }
        }
        .inner {
            position: relative;
            left: 50%;
            max-width: 1440px !important;
            display: block;
            max-width: inherit;
            @include transform(translate(-50%, 0));
            img {
                display: block;
            }
            @media (max-width: 1440px) {
                width: 146%;
            }
        }
        .slick-list {
            padding: 0;
        }
        .container {
            position: relative;
            .slick-dots {
                position: relative;
                right: 0;
                width: auto;
                bottom: 0;
                
               @include min-md {
                    bottom: 40px;
                }
                /* li {
                    width: inherit;
                    height: inherit;
                    margin: 0 4px;
                    button {
                        width: 16px;
                        height: 16px;
                        border: solid 1px #222;
                        -webkit-border-radius: 20px;
                        border-radius: 20px;
                        background: #d0d2d3;
                    }
                }
                .slick-active {
                    button {
                        background: #ec1b23;
                    }
                } */
            }
        }
    }
}
.products-list {
    overflow: hidden;
    //text-align: center;
    &__item {
        display: inline-block;
        width: 245px;
        margin-bottom: 20px;
        text-align: center;
        @extend %transition;
        .slick-initialized & {
            display: block;
            width: auto;
            margin-bottom: 0;
        }
        &:hover {
            background: #f0f0f0;
            @extend %transition;
        }
    }
}

.chamadas {
    &-container {
        margin-bottom: 40px;
        background: #d0d2d3;
    }
    .row {
        margin: 0;
        .col {
            @include min-sm {
                &:nth-of-type(1) {
                    padding-right: 0;
                }
                &:last-child {
                    padding-left: 0;
                }
            }
        }
    }
    a {
        float: left;
    }
}
