.header {
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &-container {
        display: none;
        position: relative;
        z-index: 10;
        background: #fff;
        @include min-lg {
            display: block;
            padding-top: 0;
            border-top: inherit;
            border-bottom: solid 2px $color-1;
            background: url('../../images/header-bg.png') center 0 repeat-x #333;
            background-size: cover;
        }
        .container {
            max-width: 1440px;
        }
    }

    &__logo-container {
        margin: 0;
        @include min-lg {
            position: absolute;
            z-index: 20;
            top: -8px;
            left: 0;
            display: inline-block;
            width: 250px;
            height: 145px;
            margin: 0;
            padding: 32px 0 0;
            text-align: center;
            background: url('../../images/logo-container.png') no-repeat;
        }
    }    
    
    &__menu {
        display: flex;
        align-self: flex-end;
        height: 100%;
        align-items: center;
        padding-left: 25%;
        padding-right: 16px;
        justify-content: space-evenly;
        width: 100%;
        max-width: 920px;
        margin: 0;
        @include min-lg {
            max-width: 1180px;
        }
        @include min-xl {
            max-width: 1000px;
            padding-left: 0;
            padding-right: 0;
        }
        &__link {
            padding: 0 10px 0 5px;
            text-transform: uppercase;
            color: #fff;
            display: flex;
            height: 100%;
            align-items: center;
            @extend %transition;

            @include min-lg {
                font-size: 15px;
            }

            @include min-xl {
                font-size: 18px;
            }
            &:not(.is-active):hover {
                opacity: 0.7;
            }
            &.is-active {
                color: $color-1;
                transition: none;
            }
            &--lupe {
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 10px;
                &:hover {
                    .icon-search {
                        &::before {
                            @extend %transition;
                            color: $color-1;
                        }
                    }
                }
            }
            &--button {
                background: $color-1;
                border-radius: 20px;
                color: #fff;
                display: flex;
                height: 40px;
                line-height: 1;
                padding: 0 12px;
                align-items: center;
                &:hover {
                    color: #FFF;
                    opacity: 0.8;
                }
            }
        }
        >li {
            height: 100%;
            align-items: center;
            display: flex;
        }
    }
    &__search {
        background: #fff;
        display: none;
        padding: 16px;
        position: absolute;
        right: 30px;
        top: 86px;
        border-top: none;
        z-index: 0;
        border: 1px solid #ddd;
        form {
            display: flex;
            gap: 8px;
        }
        &__input {
            font-size: 12px;
            width: 100%;
            height: 34px;
            text-indent: 8px;
            margin: 0 !important;
            padding: 0 !important;
            @extend %transition;
            opacity: .7;
            border: 1px solid #666;
            background: #111;
            &:focus {
                opacity: 1;
            }
            @include max-md {
                &:focus {
                    border-bottom: inherit !important;
                }
            }
        }
        &__btn {
            font-weight: 600;
            top: 0;
            right: 0;
            height: 38px;
            padding: 0 10px;
            opacity: .5;
            color: #fff;
            border: none;
            background: #222;
            @extend %transition;
            &:hover {
                opacity: 1;
            }
        }
    }
    &-mobile {
        &__search {
            position: relative;
            display: none;
            margin: 22px 0;
            top: 0;
            right: 0;
           
            &__input {
                font-size: 12px;
                width: 100%;
                height: 34px;
                text-indent: 15px;
                margin: 0 !important;
                padding: 0 !important;
                @extend %transition;
                @include min-lg {
                    float: right;
                    width: 80%;
                    opacity: .7;
                    color: #fff;
                    border: 1px solid #666;
                    background: #111;
                    padding-right: 68px;
                    padding-left: 10px;
                    &:focus {
                        width: 100%;
                        opacity: 1;
                        border: 1px solid #fff !important;
                        background: #000;
                    }
                }
                @include max-md {
                    &:focus {
                        border-bottom: inherit !important;
                    }
                }
            }
            &__btn {
                font-weight: 600;
                position: absolute;
                top: 0;
                right: 0;
                height: 34px;
                padding: 0 10px;
                opacity: .5;
                color: #fff;
                border: none;
                background: #222;
                @extend %transition;
                &:hover {
                    opacity: 1;
                }
                @include min-lg {
                    width: 34px;
                    text-indent: -9999px;
                    background: url('../../images/lupe.svg') no-repeat center center transparent;
                    background-size: 18px 18px;
                }
            }
        }
    }
    &-mobile-container {
        background: #FFF;
        position: relative;
        z-index: 10;
        min-height: 60px;
        padding: 10px 0;
        background: #fff;
        border-bottom: 1px solid #ddd;
        &.is_stuck {
            box-shadow: 0 1px 4px #ccc;
        }
        @include min-lg {
            display: none;
        }
        .header-mobile__logo {
            margin: 0;
            float: left;
            @include max-md {
                img {
                    float: left;
                    max-width: 180px;
                }
            }
        }
        .button-collapse {
            font-size: 30px;
            position: relative;
            top: 3px;
            float: right;
            color: #222;
            @include min-lg {
                color: #fff;
            }
        }
        .search-mobile-btn {
            font-size: 24px;
            float: right;
            top: 3px;
            position: relative;
            padding: 4px 0;
            @include min-lg {
                //display: none;
            }
        }
    }

    .icon-search:before {
        color: #fff;
        font-size: 20px;
    }
}