$color-gray-light: #ecedee;
$color-gray-dark: #313131;

$color-1: #EB1A22; //Vermelho
$color-2: #791777; //Roxo
$color-3: #9D090E; //Bordô
$color-4: #B08945; //Marrom
$color-5: #EB008B; //Pink
$color-6: #00ADEE; //Azul
$color-7: #00B4A4; //Verde Água
$color-8: $color-gray-light; //Cinza Claro

//Mobile-First - Materializer
$min-xs: only screen and (min-width: 481px); //Small Phone
$min-sm: only screen and (min-width: 601px); //Phone & Tablet Vert
$min-md: only screen and (min-width: 993px); //Desktop & Tablet Horiz

//Desktop-First
$max-md: only screen and (max-width: 992px); //Phone & Tablet Vert
$max-sm: only screen and (max-width: 600px); //Phone
$max-xs: only screen and (max-width: 480px); //Small Phone
