.relatory {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__item {
    padding-bottom: 8px;

    &:not(:last-child) {
      border-bottom: solid 1px #ddd;
    }

    > a {
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
