.showcase {
    padding: 32px 0;
    overflow: hidden;
    &__title {
        font-size: 18px;
        font-weight: 600;
        position: relative;
        bottom: -9px;
        display: inline-block;
        margin: 0;
        padding: 0 10px;
        text-transform: uppercase;
        color: #666;
        background: #fff;
        &-box {
            position: relative;
            margin-bottom: 15px;
            border-bottom: 1px solid #666;
        }
    }
    &.showcase--lancamentos {
        .showcase__title-box {
            border-bottom: 1px solid $color-1;
            .showcase__title {
                color: $color-1;
            }
        }
    }
    &.showcase--destaques {
        .showcase__title-box {
            border-bottom: 1px solid $color-7;
            .showcase__title {
                color: $color-7;
            }
        }
    }
    &.showcase--tipos {
        .showcase__title-box {
            margin-bottom: 30px;
            border-bottom: 1px solid #333;
            .showcase__title {
                color: #333;
            }
        }
    }
    &.showcase--linhas {
        .showcase__title-box {
            margin-bottom: 30px;
            border-bottom: 1px solid #333;
            .showcase__title {
                color: #333;
            }
        }
    }
    &.showcase--bgGray {
        background-color: $color-gray-light;
        .showcase__title-box {
            border-bottom: 1px solid $color-1;
            .showcase__title {
                color: $color-1;
                background-color: $color-gray-light;
            }
        }
    }
}