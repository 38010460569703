.page-representantes {
    .content-container {
        padding: 20px 0 0; 
        @include min-md{
            padding: 80px 0 0;
        }
    }
    .attendance {
        .address-list__address {
            position: relative;
            &:after {
                position: absolute;
                right: 0;
                bottom: 0;
                display: inline-block;
                width: 181px;
                height: 215px;
                content: '';
                background: url('../../images/representante-figure.jpg') 0 0 no-repeat;
            }
        }
    }
}
.representative {
    margin-bottom: 30px;
    &.hidden {
        display: none;
    }
    &__list {
        @include min-md {
            min-height: 220px;
        }
        &.loading {
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: 9999;
                width: 40px;
                height: 40px;
                left: 50%;
                top: 50%;
                margin-left: -20px;
                margin-top: -20px;
                background: url('../../images/loading.gif') no-repeat center center;
                background-color: rgba(#fff, 0.7);
                -webkit-border-radius: 5px;
                border-radius: 5px;
                -webkit-box-shadow: 0 0 6px 5px rgba(0, 0, 0, 0.1);
                box-shadow: 0 0 6px 5px rgba(0, 0, 0, 0.1);
            }
            .representative__title {
                opacity: 0.4 !important;
            }
            .representative__list__inner-list, 
            .representative__list__place, 
            .representative__list__zone, 
            &>p {
                opacity: 0.4 !important;
            }
        }
        &__inner-list {
            padding-right: 10px;
            max-height: 470px;
            overflow: auto;
        }
        &__title {
            padding-bottom: 0;
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: bolder;
            text-transform: uppercase;
            color: #333;
        }
        &__zone {
            margin-bottom: 10px;
            margin-top: 1.3em;
            padding-top: 1.3em;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            color: $color-1;
            border-top: 1px solid #ddd; 
            /* &:before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                margin-bottom: 20px;
                background-color: #ccc;
            } */
            &:first-of-type {
                padding-top: 0;
                border-top: none;
            }
        }
        &__place {
            margin-bottom: 7px;
            margin-top: 15px;
            font-size: 15px;
            font-weight: 600;
            color: #000;
        }
        p + .representative__list__place {
            padding-top: 1em;
            border-top: 1px dotted #ccc; 
        }
        p {
            line-height: 1.8;
            color: #636363;
            strong {
                font-weight: bold;
            }
        }
        &__whatsapp {
            margin-top: 4px;
            background-color: #01973F;
            color: #fff;
            padding: 2px 8px;
            border-radius: 4px;
            display: inline-block;
            @extend %transition;
            &:hover {
                background-color: #07AD1D;
            }
            &--international {
                margin-top: 0;
            }
        }
    }
    &__map {
        &__list {
            display: none;
        }
        &__filter {
            padding: 8px;
            font-size: 15px;
            border: solid 1px #ccc;
            color: #666;
            text-transform: uppercase;
            max-width: 260px;
            margin: 15px 0 20px;
            @include min-md {
                margin-left: auto;
                margin-right: auto;
            }
        }
        svg {
            display: none;
        }
        @include min-md {
            &>p {
                display: none;
            }
            svg {
                display: block;
            }
        }
    }
    //Comportamentos específicos do Touch Screen
    .touch-screen & {
        .representative__list__inner-list {
            max-height: none;
            padding-bottom: 30px;
            border-bottom: 2px solid #333;
        }
    }
}
#representativeMap .state {
    cursor: pointer;
}
/* Tabs*/
.representative-tabs {
    margin-bottom: 20px;
    padding-bottom: 0;
    border-bottom: 1px solid #ddd;
    a {
        display: inline-block;
        padding: 10px 40px;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 600;
        color: #8F8F8F;
        background: #F7F8F9;
        transition: ease all 0.3s;
        &.active {
            color: #000;
            background: #EBECED;
        }
        &:hover {
            color: #333;
        }
    }
}
.representative-world-map {
    @include min-md {
        display: inline-block;
    }
    display: none;
    margin-top: 30px;
    width: 100%;
    text-align: center;
}
