.shelf {
    font-size: 14px;
    font-weight: 300;
    max-width: 245px;
    margin: 0 auto;
    padding: 15px;
    &__flag {
        &--new {
            position: absolute;
            z-index: 1;
            top: 10px;
            left: 10px;
            max-width: 45px;
        }
    }
    &__image {
        position: relative;
        margin-bottom: 10px;
        width: 100%;
        max-width: 215px;
        min-height: 165px;
        background: $color-8;
        img {
            width: 100%;
        }
        a {
            display: block;
        }
        .shelf__label {
            position: absolute;
            z-index: 1;
            top: 10px;
            right: 5px;
            max-width: 45px;
            li {
                margin-bottom: 10px;
            }
        }
    }
    &__title {
        font-size: 14px;
        font-weight: 700;
        overflow: hidden;
        //height: 32px;
        //max-height: 32px;
        margin: 0 0 5px;
        text-align: center;
        text-transform: uppercase;
        a:hover {
            color: $color-1;
        }
        span {
            margin-left: 3px;
            font-weight: 600;
        }

        
    }
    &__info {
        .feedstock {
            font-size: 12px;
            font-weight: 400;
            display: inline-block;
            width: 24px;
            padding: 2px;
            text-align: center;
            letter-spacing: 1px;
            color: #fff;
            border-radius: 3px;
            background: #666;
        }
    }
    &__desc-1 {
        min-height: 1.3em;
        line-height: 1.3em;
        font-weight: 300;
        text-transform: uppercase;
    }
    &__desc-2 {
        margin-bottom: 10px;
        text-transform: uppercase;
    }
    &__line {
        font-size: 12px;
        font-weight: 600;
        padding: 8px 4px;
        color: #fff;
        border-radius: 20px;
        background-color: #ccc;
        text-transform: uppercase;
        &.empty {
            background: none !important;
            &:before {
                content: '_';
                color: transparent;
            }
        }       
    }
    &__subline {
        font-size: 13px;
        padding: 6px 0;
    }
    .product-available-colors {
        position: absolute;
        z-index: 9;
        height: 20px;
        bottom: 5px;
        @media (max-width: 780px) {
            //margin-top: -70px;
        }
        ul {
            width: 100%;
            li {
                width: 16px;
                height: auto;
                span {
                    width: 14px;
                    height: 14px;
                    border: 1px solid #999;
                    margin-top: 0;
                    &.white, &.assorted, &.translucent, &.transparent, &.cristal {
                        &:before {
                            font-size: 8px;
                            line-height: 12px;
                        }
                    }
                }
            }
        }
    }


    &-tipos {
        &__item {
            max-width: 180px;
            margin: 0 auto;
            text-align: center;
            padding: 10px;
            border: 1px solid transparent;
            @extend %transition;
            &:hover, &.active {
                .shelf-tipos__image {
                    @include filter(grayscale(0%));
                }
                .shelf-tipos__title {
                    color: $color-1;
                }
            }
            
        }
        &__image {
            margin: 0 auto 5px;
            @include filter(grayscale(100%));
            img {
                display: inline-block;
            }
        }
        &__title {
            font-size: 9px;
            font-weight: 600;
            overflow: hidden;
            height: 30px;
            max-height: 30px;
            margin: 0;
            text-transform: uppercase;
            text-align: center;
            word-wrap: break-word;
            @extend %transition;
        }

        &:not(.filter-tipos) .shelf-tipos__item {
            min-width: 180px;
            margin-bottom: 20px;
            //Assim que o slider for iniciado
            .slick-initialized & {
                display: block;
                min-width: 0;
                margin-bottom: 0;
                .shelf-tipos__image {
                    img {
                        display: block;
                    }
                }
            }
        }
    }

    &-linhas {
        &__item{
            display: block;
            float: left;
            min-width: 193px;
            margin-bottom: 10px;
            margin-top: 10px;
            padding: 0 12px;
            &:hover {
                .shelf-linhas__title {
                    @extend .hvr-float-shadow;
                }
            }
        }
        &__title {
            text-align: center;
            height: 41px;
            margin: 0 0 10px;
            padding-right: 10px;
            padding-left: 10px;
        }
        &__title-link {
            font-size: 16px;
            font-weight: 700;
            display: table-cell;
            width: 400px;
            height: 41px;
            padding: 5px;
            text-transform: uppercase;
            color: #fff;
            vertical-align: middle;
            line-height: 1;
            -webkit-border-radius: 20px;
            border-radius: 20px;
            background: #666;
            @extend .effect-bg-sature;
            
            &.line-bg-7 {
                @extend .line-bg-7;
                // background: #7ab8e0;
                // background: -moz-linear-gradient(left,#7ab8e0 0,#fd9326 100%);
                // background: -webkit-linear-gradient(left,#7ab8e0 0,#fd9326 100%);
                // background: linear-gradient(to right,#7ab8e0 0,#fd9326 100%);
            }

        }
        &__desc {
            text-align: center;
            -webkit-user-select: all;
            -khtml-user-select: all;
            -moz-user-select: all;
            -ms-user-select: all;
            user-select: all;
            strong {
                font-weight: 700;
            }
        }
        //Assim que o slider for iniciado
        .slick-initialized & {
            min-width: 0;
            max-width: none;
            margin-bottom: 0;
        }
    }
}