.Lgtb {
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 16px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    @include min-sm {
        display: grid;
        grid-template-columns: 12fr 1fr;
    }
    &-container {
        padding-top: 10px;
        padding-bottom: 10px;
        z-index: 101;
        width: 100%;
        display: none;
        position: fixed;
        bottom: 0;
        background-color: #ededed;
        box-shadow: rgba(0, 0, 0, .1) 0 -3px 2px 0;
        &.active {
            display: flex;
            justify-content: center;
        }
    }
    &-message {
        color: #333;
        line-height: 1.5;
        span {
            font-weight: 600;
            white-space: nowrap;
        }
    }
    &-link {
        font-weight: 600;
        text-decoration: underline;
    }
    &-button {
        min-width: 190px;
        padding: 6px 12px;
        border-radius: 20px;
        cursor: pointer;
        background: #000;
        color: #fff;
        text-align: center;
        &:hover {
            background: $color-6;
        }
    }
}
